<template>
  <v-app>
    <v-system-bar v-if="false"></v-system-bar>
    <router-view/>
  <bottom-nav/>
  
  </v-app>
</template>

<script>
 import BottomNav from '@/components/common/BottomNav'

export default {
    components: {
       'bottom-nav': BottomNav
    },
    data() {
      return {
      }
    },

}
</script>

