<template>
   <v-bottom-navigation app grow  height="80" color="primary">
        <v-btn color="natural" value="home" to="/main/home" >
      <span>หน้าแรก</span>
      <v-icon >mdi-home</v-icon>
    </v-btn>
     <v-btn color="natural" value="friend" to="/main/addfriend" >
      <span >เพิ่มเพื่อน</span>
      <v-icon >mdi-account-supervisor</v-icon>
    </v-btn>

    <v-btn color="natural" value="indox" to="/main/inbox" >
      <span >ข้อความ</span>
      <v-icon >mdi-message-text</v-icon>
    </v-btn>

    <v-btn color="natural" value="settings" to="/main/setting">
      <span >ตั้งค่า</span>

      <v-icon >mdi-cog</v-icon>
    </v-btn>

    </v-bottom-navigation>
</template>

<script>
export default {
    data() {
        return {
            value: null
        }
    },

}
</script>
<style lang="scss" scoped>
.v-item-group.v-bottom-navigation .v-btn.v-size--default {
        height: inherit;
    }
//     .v-btn__content {
//     align-items: center;
//     color: inherit;
//     display: flex;
//     flex: 1 0 auto;
//     justify-content: flex-end;
//     line-height: normal;
//     position: relative;
//     transition: inherit;
//     transition-property: opacity;
//     margin-top: 10px;
// }
</style>
    
